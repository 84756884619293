import { Grid } from '@material-ui/core'
import React from 'react'
import { components,useTranslation } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import Namespace from 'src/constants/locale/Namespace'
import SCOAdminKeys from 'src/constants/locale/key/SCOAdmin'
import SCOAdminTimeZoneView from './SCOAdminTimeZoneViewPage'

const { CngTabs } = components

function ViewPage({ showNotification }) {
  
  const { code } = useParams()
  const { translate } = useTranslation([Namespace.SCO_ADMIN])
  const translatedTextsObject = makeTranslatedTextsObject()
 
  function makeTranslatedTextsObject() {
    let viewSCOAdminTimezone = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.TIMEZONE_VIEW
    )
    let name= translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.TIMEZONE_NAME
    )
    let utcOffset= translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.TIMEZONE_UST_OFFSET
    )
    let dstOffset= translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.TIMEZONE_DST_OFFSET
    )
    let value= translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.TIMEZONE_VALUE
    )
    return {
      viewSCOAdminTimezone,
      name,
      dstOffset,
      utcOffset,
      value
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.viewSCOAdminTimeZone,
              tabContent: (
                <SCOAdminTimeZoneView
                  showNotification={showNotification}
                  countryCode={code}
                
                />
              )
            }
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default ViewPage
