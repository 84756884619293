import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import React from 'react'
import SCOAdminKeys from 'src/constants/locale/key/SCOAdmin'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification
    
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  filter: {EQUAL, IN, LIKE},
} = constants

function TablePage(props) {

  const {
    showNotification
  } = props
  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.SCO_ADMIN
  ])
  const translatedTextsObject = makeTranslatedTextsObject()


  function makeTranslatedTextsObject() {
    let scoAdmin = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.TIMEZONE_TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: scoAdmin
      }
    )
   
    let value= translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.TIMEZONE_VALUE
    )
    let offsetSeconds = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.TIMEZONE_OFFSET_SECONDS
    )
    
    let offsetString = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.TIMEZONE_OFFSET_STRING
    )
    let name = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.TIMEZONE_NAME
    )
    let status = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.TIMEZONE_STATUS
    )
    return {
      scoAdmin,
      tableTitle,
      value,
      name,
      offsetSeconds,
      offsetString,
      status
    }
  }

  const columns = [
    {
      field: "name",
      title: translatedTextsObject.name,
      filterPlaceholder:' '
    },
    {
      field: "utcOffset",
      title: translatedTextsObject.offsetString,
      filterPlaceholder:' '
    },
    {
      field: "dstOffset",
      title: translatedTextsObject.offsetSeconds,
      filterPlaceholder:' '
    },
    {
      field: "status",
      title: translatedTextsObject.status,
      filterPlaceholder:' ',
      type: "boolean"
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              //{...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              columns={columns}
              fetch={{ url: SCOAdminApiUrls.GET_TIMEZONE_LIST }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
             
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
 
}

export default TablePage
