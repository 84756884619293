import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/pathMap'
import { useLocation } from 'react-router-dom'

const {
  form: { CngAddForm ,CngAddFormButtonSection}
} = components

function AddPage({ history, showNotification ,row}) {
  const loc = useLocation()
  let formikPropsData;
  if(loc.state !== undefined) {
 
  formikPropsData = {
  ...FormProperties.formikProps,
  initialValues: loc.state 
  }
 } else {
 
  formikPropsData = FormProperties.formikProps
 }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngAddForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          formikProps={formikPropsData}
          bodySection={
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              mode={"add"}
            />
          }
           
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
        
          create={{
            url: SCOAdminApiUrls.CREATE_CODE_MASTER,
            successRedirect: pathMap.SCO_ADMIN_CODE_TYPE_LIST_VIEW
          }}
        />
      </Grid>
    </Grid>
  )
}

export default AddPage
