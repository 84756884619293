import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import React, { useRef } from 'react'
import SCOAdminKeys from 'src/constants/locale/key/SCOAdmin'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification
    
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  filter: {EQUAL, IN, LIKE},
} = constants

function TablePage(props) {
  const {
    showNotification
  } = props
  
  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.SCO_ADMIN
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  const cngTableRef = useRef()
  
  function makeTranslatedTextsObject() {
    let scoAdmin = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.COUNTRY_CODE_TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: scoAdmin
      }
    )
   
    let code = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.COUNTRY_CODE
    )
    let countryName = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.COUNTRY_NAME
    )
    let countryAlpha3 = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.COUNTRY_ALPHA3
    )
    let status = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.COUNTRY_STATUS
    )
    
    return {
      scoAdmin,
      tableTitle,
      code,
      countryName,
      countryAlpha3,
      status
    }
  }

  const columns = [
    {
      field: "code",
      title: translatedTextsObject.code,
      filterPlaceholder:' ',
      filterCellStyle: { maxWidth: '100px' },
      
    },
    {
      field: "alpha3",
      title: translatedTextsObject.countryAlpha3,
      filterPlaceholder:' ',
      filterCellStyle: { maxWidth: '100px' },
      
    },
    {
      field: "name",
      title: translatedTextsObject.countryName,
      filterPlaceholder:' ',
      filterCellStyle: { maxWidth: '100px' },
      defaultSort: "asc",
    },
    {
      field: "status",
      title: translatedTextsObject.status,
      filterPlaceholder:' ',
      filterCellStyle: { maxWidth: '100px' },
      type: "boolean"
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
             
              columns={columns}
              fetch={{ url: SCOAdminApiUrls.GET_COUNTRY_LIST }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
             
              cngTableRef={cngTableRef}
            />
          </Grid>
        </Grid>
      </CardContent>
     </Card>
  )
  
}

export default TablePage
