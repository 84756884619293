import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation,
  DataFlattener,
  useServices
} from 'cng-web-lib'

import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import React, { useRef } from 'react'
import SCOAdminKeys from 'src/constants/locale/key/SCOAdmin'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCustomLookup
    
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
} = constants

function TablePage(props) {

  const {
    location: { pathname },
    showNotification
  } = props
  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.SCO_ADMIN
  ])
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCustomLookup = useFetchCustomLookup();
  const cngTableRef = useRef()
  const {deleteRecord} = useServices()

  function makeTranslatedTextsObject() {
    let scoAdmin = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.CODE_MASTER_TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: scoAdmin
      }
    )
   
    let code = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.CODE_MASTER_CODE
    )
    let codeType = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.CODE_MASTER_CODETYPE
    )
    let status = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.CODE_MASTER_STATUS
    )
    
    let description = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.CODE_MASTER_DESCRIPTION
    )
    let name = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.CODE_MASTER_NAME
    )
    let alpha3 = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.CODE_MASTER_ALPHA3
    )
   
    return {
      scoAdmin,
      tableTitle,
      code,
      codeType,
      status,
      description,
      name,
      alpha3
    }
  }

  const columns = [
    {
      field: "codeType",
      title: translatedTextsObject.codeType,
      customLookup: () => {
        return fetchCustomLookup(
          SCOAdminApiUrls.GET_CODE_MASTER_LIST,
          {},
          'content',
          'codeType',
          'codeType',
          (error) => {
            console.log(error)
          }
        )
      }
    },
    {
      field: "code",
      title: translatedTextsObject.code
    },
    {
      field: "name",
      title: translatedTextsObject.name
    },
    {
      field: "description",
      title: translatedTextsObject.description
    },
    {
      field: "status",
      title: translatedTextsObject.status,
      type: "boolean"
    },
    {
      field: "createdDate",
      title: "",
      defaultSort: "desc",
      type: 'datetime',
      filtering: false,
      hidden: true
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: SCOAdminApiUrls.DELETE_CODE_MASTER}}
              deleteButtonProps= {{
                onClick: (e,rowData) => {
                 deleteRecord.execute(SCOAdminApiUrls.DELETE_CODE_MASTER, 
                  toServerDataFormat(rowData) ,
                  (data)=> {
                    showNotification("success", "Record deleted successfully")
                    if (cngTableRef.current.performRefresh) {
                      cngTableRef.current.performRefresh()
                    }
                  },
                  (error)=> {
                    console.log(error)
                  }
                  )
                }
              }
              }
              editRoute={`${pathname}/edit`}
              fetch={{ url: SCOAdminApiUrls.GET_CODE_MASTER_LIST }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
              cngTableRef={cngTableRef}
              //exportData={{ url: SCOAdminApiUrls.EXPORT }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
  
}

function toServerDataFormat(localData) {
  const reqData = { "codeType" : localData.codeType ,
  "data":[{"code":localData.code, "status":localData.status ===true ? 'Active':'Inactive', 
  "name" : localData.name , "description":localData.description}]  } 
return DataFlattener.unflatten(reqData);
}
export default TablePage
