import { Yup ,constants} from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants
const today = new Date();
  today.setHours(0, 0, 0, 0);
function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  

  return Yup.object(
    {
    code: Yup.string().required(requiredMessage).max(50,"Max length allowed is 50").nullable(),
    status: Yup.boolean().nullable(),
    codeType: Yup.string().required(requiredMessage).nullable(),
    name: Yup.string().required(requiredMessage).max(100,"Max length allowed is 100").nullable(),
    description: Yup.string().required(requiredMessage).max(1000,"Max length allowed is 1000").nullable(),
    })  
}

export default makeValidationSchema
