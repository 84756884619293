import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
import FormProperties from './FormProperties'
import { Grid } from '@material-ui/core'
import React,{ useState} from 'react'
import { components ,useServices} from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import pathMap from 'src/paths/pathMap'

const {
  form: { CngEditForm ,
    },
  table: { useDefaultNotification }
} = components

function EditPage({ history, showNotification }) {
  const { id } = useParams()
  
  const {
    success: showSuccessNotification,
    error: showErrorNotification
  } = useDefaultNotification(showNotification)

  const { updateRecord } = useServices();

  const onSubmit = (dataForm) => {
    const row = { "codeType" : dataForm.codeType ,
    "data":[{"code":dataForm.code, "status":dataForm.status ===true ? 'Active':'Inactive', 
    "name" : dataForm.name , "description": dataForm.description}]  } 

    const onSuccess = () => {
      showSuccessNotification("Update Successful");
      history.push(pathMap.SCO_ADMIN_CODE_TYPE_LIST_VIEW )
    }

    const onError = () => {
      showErrorNotification("Update Record failed");
    }

    updateCodeMasterInfo(row, onSuccess, onError);
  }

  const updateCodeMasterInfo = (dataForm, onSuccess, onError) => {
    updateRecord.execute(
      SCOAdminApiUrls.EDIT_CODE_MASTER,
      dataForm,
      onSuccess,
      onError
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngEditForm
          fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
          history={history}
          showNotification={showNotification}
          bodySection={
            <FormProperties.Fields
              disabled={false}
              showNotification={showNotification}
              mode={"edit"}
            />
          }
          toClientDataFormat={FormProperties.toClientDataFormat}
          toServerDataFormat={FormProperties.toServerDataFormat}
          formikProps={{
            initialValues: FormProperties.formikProps.initialValues,
            makeValidationSchema: FormProperties.formikProps.makeValidationSchema,
            onSubmit: onSubmit
          }}
          fetch={{
            url: SCOAdminApiUrls.GET_CODE_MASTER_LIST
          }}
          id={id}
        />
      </Grid>
    </Grid>
  )
}

export default EditPage
