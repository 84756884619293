import React  from 'react'
import Namespace from 'src/constants/locale/Namespace'
import SCOAdminKeys from 'src/constants/locale/key/SCOAdmin'
import { Grid, Card, CardContent } from '@material-ui/core'
import {
  components,
  DataFlattener,
  useTranslation,
  constants
} from 'cng-web-lib'


const {
  card: {
    CngSimpleCardHeader
  },
  form: {
    adapter: { 
      useFormAdapter:{ useField } 
    },
    field: {
      CngTextField
      
    },
  
    
  },
  CngGridItem,
} = components
const {
  filter: {EQUAL}
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  name: "",
  value:"",
  offsetSeconds: "",
  offsetString: ""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.SCO_ADMIN)
  const translatedTextsObject = makeTranslatedTextsObject();
  
  function makeTranslatedTextsObject() {

    let  timezoneTitle= translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.TIMEZONE_TITLE
    )
    let name= translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.TIMEZONE_NAME
    ) 
    let value= translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.TIMEZONE_VALUE
    )
    let offsetString = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.TIMEZONE_OFFSET_STRING
    )
    let offsetSeconds = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.TIMEZONE_OFFSET_SECONDS
    )
    
    return {
      timezoneTitle,
      name,
      value,
      offsetString,
      offsetSeconds
    }
  }

	return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CngSimpleCardHeader title={translatedTextsObject.header} />
          <CardContent>
            <Grid container spacing={3}>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.value}>
             <CngTextField
                  name="value"
                  label={translatedTextsObject.value } 
                  disabled={true}
                  />
             </CngGridItem>   
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.utcOffset}>
                <CngTextField
                  name="utcOffset"
                  label={translatedTextsObject.utcOffset }
                  disabled={true}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.dstOffset}>
                <CngTextField
                  name="dstOffset"
                  label={translatedTextsObject.dstOffset }
                  disabled={true}
                />
              </CngGridItem>
           </Grid>
          </CardContent>
        </Card>
      </Grid>
        
    </Grid>
	)
}

function toClientDataFormat(serverData) {
  
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
 
   
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})



export default FormProperties
