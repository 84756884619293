import { Grid } from '@material-ui/core'
import React from 'react'
import { components,useTranslation } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import Namespace from 'src/constants/locale/Namespace'
import SCOAdminKeys from 'src/constants/locale/key/SCOAdmin'
import SCOAdminCountyCodeView from './SCOAdminCountryCodeViewPage'

const { CngTabs } = components

function ViewPage({ showNotification }) {
  
  const { id } = useParams()
  const { translate } = useTranslation([Namespace.SCO_ADMIN])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let viewSCOAdminCountryCode = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.COUNTRY_VIEW
    )
    let code= translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.COUNTRY_CODE
    )
    let name= translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.COUNTRY_NAME
    )
    let alpha3= translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.COUNTRY_ALPHA3
    )
    return {
      viewSCOAdminCountryCode,
      code,
      name,
      alpha3
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.viewSCOAdminCountryCode,
              tabContent: (
                <SCOAdminCountyCodeView
                  showNotification={showNotification}
                  id={id}
                
                />
              )
            }
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default ViewPage
