import { BasePage as CngBasePage } from 'cng-web-lib'
import React from 'react'
import TablePage from './TablePage.js'
import ViewPage from './ViewPage.js'
import useBreadcrumbNameMap from 'src/useBreadcrumbNameMap'

function WrappedTablePage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      renderPage={(showSnackbar) => (
        <TablePage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedViewPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  return (
    <CngBasePage
      renderPage={(showSnackbar) => (
        <ViewPage showNotification={showSnackbar} {...props} />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}


export {
  WrappedViewPage as ViewPage,
  WrappedTablePage as TablePage
}
