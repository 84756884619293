import React, { useState, useEffect}  from 'react'
import Namespace from 'src/constants/locale/Namespace'
import SCOAdminKeys from 'src/constants/locale/key/SCOAdmin'
import { Grid, Card, CardContent } from '@material-ui/core'
import {
  components,
  DataFlattener,
  useTranslation,
  constants,
  useServices
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import Box from '@material-ui/core/Box'
import SCOAdminApiUrls from 'src/apiUrls/SCOAdminApiUrls'
const {
  card: {
    CngSimpleCardHeader
  },
  form: {
    adapter: { 
      useFormAdapter:{ useField ,useFormikContext} 
    },
    field: {
      CngTextField,
      CngSelectField,
      CngSwitchField,
      CngLookupAutocompleteField
      
    }
  },
  CngGridItem,
} = components
const {
  filter: {EQUAL},
  NotificationType
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  codeType: "",
  name:"",
  status:"",
  code:"",
  description:""
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap, mode }) {
  const { translate } = useTranslation(Namespace.SCO_ADMIN)
  const translatedTextsObject = makeTranslatedTextsObject();
  const [codeTypeOption, setCodeTypeOption] = useState([])
  const { fetchRecords } = useServices()

  useEffect(() => {
    getCodeTypeOptions();
  }, [])

  const getCodeTypeOptions = () => {
    const onSuccess = (response) => {
        const options = response.content.map((type) => {
          return { text: type.codeType, value: type.codeType }
        })
        setCodeTypeOption([...new Map(options.map(x=>[x['text'], x])).values()]);
    }

    fetchRecords.execute(
        SCOAdminApiUrls.GET_CODE_MASTER_LIST,
          {},
        onSuccess,
        () => {
            showNotification(NotificationType.ERROR, "Failed to fetch Code Type options")
        }
    )
  }

  function makeTranslatedTextsObject() {

    let code = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.CODE_MASTER_CODE
    ) 
    let codeType = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.CODE_MASTER_CODETYPE
    )
    
    let status = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.CODE_MASTER_STATUS
    ) 
    
    let sequence = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.CODE_MASTER_SEQEUNCE
    )
    let description = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.CODE_MASTER_DESCRIPTION
    )
    let name = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.CODE_MASTER_NAME
    )
    return {
      code,
      codeType,
      status,
      description,
      name,
      sequence
    }
  }

	return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CngSimpleCardHeader title="Code Master" />
          <CardContent>
            <Grid container spacing={3}>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.code}>
             <CngTextField
                  name="code"
                  label={translatedTextsObject.code +" *"} 
                  disabled={mode == "add" ? false : true}
                  />
             </CngGridItem> 

             <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.status}>
                <CngSwitchField
                  name="status"
                  label={"Active" }
                  disabled={disabled}
                />
              </CngGridItem>  
             
             <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.codeType}>
                <CngSelectField
                  name="codeType"
                  label={translatedTextsObject.codeType  +" *"}
                  disabled={mode == "add" ? false : true}
                  items={codeTypeOption}
                />
              </CngGridItem>
              </Grid>
              </CardContent>
              </Card>

             
          <Card>
          <Box px={2}>
          <CngSimpleCardHeader title="Description" />
          <CardContent>
          <Grid container spacing={3}> 
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.name}>
                <CngTextField
                  name="name"
                  label={translatedTextsObject.name +" *" }
                  disabled={disabled}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.description}>
                <CngTextField
                  name="description"
                  label={translatedTextsObject.description +" *" }
                  disabled={disabled}
                />
              </CngGridItem>
              </Grid>
          </CardContent>
          </Box>
        </Card>
      
        </Grid>
    </Grid>
	)
}

function toClientDataFormat(serverData) {
  
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  const reqData = { "codeType" : localData.codeType ,
  "data":[{"code":localData.code, "status":localData.status ? 'Active':'Inactive', 
  "name" : localData.name , "description":localData.description}]  } 
 
return DataFlattener.unflatten(reqData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})



export default FormProperties
