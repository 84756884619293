import { Grid } from '@material-ui/core'
import React from 'react'
import { components,useTranslation } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import Namespace from 'src/constants/locale/Namespace'
import SCOAdminKeys from 'src/constants/locale/key/SCOAdmin'
import CodeMasterViewForm from './CodeMasterViewPage'


const { CngTabs } = components

function ViewPage({ showNotification }) {
  
  const { id } = useParams()
  const { translate } = useTranslation([Namespace.SCO_ADMIN])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let viewSCOAdminCodeMaster = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.TITLE
    )
    let scoAdminViewPage = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.CODE_MASTER_VIEW
    )
    return {
      viewSCOAdminCodeMaster,
      scoAdminViewPage
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.scoAdminViewPage,
              tabContent: (
                <CodeMasterViewForm
                  showNotification={showNotification}
                  id={id}
 
                />
              )
            }
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default ViewPage
