import { Grid } from '@material-ui/core'
import React from 'react'
import { components, useTranslation } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import CodeMasterEditForm from './CodeMasterEditPage'
import Namespace from 'src/constants/locale/Namespace'
import SCOAdminKeys from 'src/constants/locale/key/SCOAdmin'

const { CngTabs } = components

function EditPage({ history, showNotification }) {
	const { id } = useParams()
  const { translate } = useTranslation([Namespace.SCO_ADMIN])

  const translatedTextsObject = makeTranslatedTextsObject()
  
  function makeTranslatedTextsObject() {
    let scoAdmin = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.TITLE
    )
    let scoAdminEditPage = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.CODE_MASTER_EDIT
    )

    return {
      scoAdmin,
      scoAdminEditPage
    }
  }

  return (

  <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.scoAdminEditPage,
              tabContent: (
                <CodeMasterEditForm
                  showNotification={showNotification}
                  id={id}
                  history={history}
                />
              )
            }
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default EditPage