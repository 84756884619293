import {  Grid } from '@material-ui/core'
import React  , { useRef ,useState}  from 'react'
import { components,useTranslation,constants } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import SCOAdminKeys from 'src/constants/locale/key/SCOAdmin'
import CodeMasterAddForm from './CodeMasterAddPage'

const {
  locale: {
    key: {
      UiComponentKeys,
    },
  },
} = constants

const { CngTabs } = components


function AddPage({ history, showNotification }) {

  const row = useRef()
  const { translate } = useTranslation([
    Namespace.SCO_ADMIN,
    Namespace.UI_COMPONENT
  ])
 
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let scoAdmin = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.CODE_MASTER_TITLE
    )
    let tabLockedMessage = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Tabs.PLEASE_SUBMIT_FIRST,
      {
        title: scoAdmin
      }
    )

    return {
      scoAdmin,
      tabLockedMessage
    }
  }
 
function makeCodeMasterAddForm() {
  
    return (
      <CodeMasterAddForm
        history={history}
        showNotification={showNotification}
        row={row}
      />
    )
  
}

return (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <CngTabs
        headerColor='primary'
        tabs={[
          {
            tabName: translatedTextsObject.scoAdmin,
            tabContent: makeCodeMasterAddForm()
          },
        ]}
      />
    </Grid>
  </Grid>
)
}


export default AddPage
