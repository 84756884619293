import { Card, CardContent, Grid } from '@material-ui/core'

import SCOAdminApiUrls from "src/apiUrls/SCOAdminApiUrls";
import React from "react";
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'
import SCOAdminKeys from 'src/constants/locale/key/SCOAdmin'
import Namespace from 'src/constants/locale/Namespace';

const {

  card: {  CngSimpleCardHeader },
  table: {
     CngCrudTable

  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants


function SCOAdminStateCodeViewPage({
  showNotification,
  countryCode
}){
  const { translate } = useTranslation([Namespace.SCO_ADMIN])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let stateCode = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.COUNTRY_STATE_CODE
    )
  
    let stateName = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.COUNTRY_STATE_NAME
    )
    let stateStatus = translate(
      Namespace.SCO_ADMIN,
      SCOAdminKeys.COUNTRY_STATE_STATUS
    )
    return {
      stateCode,
      stateName,
      stateStatus
    }
  }

  const columns = [
    {
      field: "stateName",
      title: translatedTextsObject.stateName
    },
    {
      field: "stateCode",
      title: translatedTextsObject.stateCode
    },
    {
      field: "stateStatus",
      title: translatedTextsObject.stateStatus,
      type:"boolean"

    }
  ];


  return (
    <Grid item xs={12} >
    <Card>
      <CngSimpleCardHeader  />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              //{...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              columns={columns}
              fetch={{ url: SCOAdminApiUrls.GET_COUNTRY_STATE_LIST}}
              idAccessor={countryCode}
              notification={showNotification}
              fetchFilters={
                [
                  {
                    field:'countryCode', operator:'equal', value:countryCode
                  } 
                ]
              }
            />
          </Grid>
        </Grid>
    </CardContent>    
    </Card>
</Grid>    
  )
}

export default SCOAdminStateCodeViewPage;
