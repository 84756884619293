
import { Grid } from '@material-ui/core'
import React from 'react'
import { components } from 'cng-web-lib'
import { useParams } from 'react-router-dom'
import SCOAdminStateCodeViewPage from './SCOAdminStateCodeViewPage'

const { CngTabs } = components

function ViewPage({ showNotification }) {
  
  const { countryCode} = useParams()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: "State List",
              tabContent: (
                <SCOAdminStateCodeViewPage
                  showNotification={showNotification}
                  countryCode={countryCode}
                />
              )
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default ViewPage
